#getting-started-container {
  padding: 11px 10px;
}

.w-2\/5 {
  width: 30%;
  padding: 15px 0 15px 22px;
}
.leading-10 {
  line-height: 3.5rem;
  padding-right: 22px;
}

.flex.items-center.justify-center.border.rounded.border-bf-lighter-gray {
  border-color: rgba(226, 226, 226, var(--tw-border-opacity));
}

.flex.items-center.justify-center.border.rounded.border-bf-lighter-gray:hover {
  border-color: #4a4a4a;
}

.mr-sm {
  margin-right: 4px;
  margin-top: -1px;
}

.search-ph-md::-webkit-input-placeholder {
  font-size: 14px;
}

.search-ph-md::-moz-placeholder {
  font-size: 14px;
}

.search-ph-md:hover {
  border-color: #4a4a4a;
}

.attachment-text-md {
  font-family: 'Circular-Medium';
  font-size: 12px;
}

.showpage-filter-top-wrapper {
  margin-bottom: 16px;
}

.bf-collections-anchor-text {
  color: #4a4a4a !important;
  margin-right: 10px;

  &:hover {
    text-decoration-color: transparent !important;
  }
}

.attachment-adjust-margin {
  transform: translateY(-0.5px);
}

.advanced-filter-apply-overlay {
  position: sticky;
  bottom: 0;
  z-index: 5;
  background-color: rgba(255, 255, 255, 0.925);
  max-width: 400px;
  width: inherit;
}

.right-0 {
  right: 0;
}
