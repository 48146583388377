@use './themes';
@use 'assets/fonts/inter/Inter';

$circularFontURL: 'https://fonts.brandfolder.com/circular/';

@font-face {
  font-family: 'Circular-Bold';
  font-display: swap;
  src: url($circularFontURL + 'lineto-circular-bold.eot');
  src: url($circularFontURL + 'lineto-circular-bold.eot?#iefix') format('embedded-opentype'),
    url($circularFontURL + 'lineto-circular-bold.woff2') format('woff2'),
    url($circularFontURL + 'lineto-circular-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Circular-Book';
  font-display: swap;
  src: url($circularFontURL + 'lineto-circular-book.eot');
  src: url($circularFontURL + 'lineto-circular-book.eot?#iefix') format('embedded-opentype'),
    url($circularFontURL + 'lineto-circular-book.woff2') format('woff2'),
    url($circularFontURL + 'lineto-circular-book.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Circular-Medium';
  font-display: swap;
  src: url($circularFontURL + 'lineto-circular-medium.eot');
  src: url($circularFontURL + 'lineto-circular-medium.eot?#iefix') format('embedded-opentype'),
    url($circularFontURL + 'lineto-circular-medium.woff2') format('woff2'),
    url($circularFontURL + 'lineto-circular-medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Circular-Italic';
  font-display: swap;
  src: url($circularFontURL + 'lineto-circular-bookitalic.eot');
  src: url($circularFontURL + 'lineto-circular-bookitalic.eot?#iefix') format('embedded-opentype'),
    url($circularFontURL + 'lineto-circular-bookitalic.woff2') format('woff2'),
    url($circularFontURL + 'lineto-circular-bookitalic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Circular-Italic-Medium';
  font-display: swap;
  src: url($circularFontURL + 'lineto-circular-mediumitalic.eot');
  src: url($circularFontURL + 'lineto-circular-mediumitalic.eot?#iefix') format('embedded-opentype'),
    url($circularFontURL + 'lineto-circular-mediumitalic.woff2') format('woff2'),
    url($circularFontURL + 'lineto-circular-mediumitalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Circular-Black';
  font-display: swap;
  src: url($circularFontURL + 'lineto-circular-black.eot');
  src: url($circularFontURL + 'lineto-circular-black.eot?#iefix') format('embedded-opentype'),
    url($circularFontURL + 'lineto-circular-black.woff2') format('woff2'),
    url($circularFontURL + 'lineto-circular-black.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

